import React, { useMemo } from 'react'
import { DateTime } from 'luxon'
// import LinearProgress from '@mui/material/LinearProgress'
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextFieldFilter,
  // Text,
} from 'components'
import { Cancel as CancelIcon } from 'icons'
import { useCurrentSchoolLocation } from '@kiosk/hooks'
import {
  Download as DownloadIcon,
} from 'icons'
import { FilterState, FilterProps } from '@kiosk/hooks/useFilteredKioskUsers'
import { useViewport } from 'hooks'

export type FiltersHeaderProps = {
  filters: FilterState
  filteredCount: number
  totalCount: number
} & Partial<Pick<FilterProps, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "loading" | "loadedAt" | "availableRosters" | "availableMenus">>

export const FiltersHeader: React.FC<FiltersHeaderProps> = ({ /*loading, loadedAt,*/ filters, appliedFilters, filterDefinitions, availableRosters, availableMenus, filteredCount, totalCount, setFilters, applyFilters, setAndApplyFilters }) => {
  const { isSmall } = useViewport()
  const { schoolLocation } = useCurrentSchoolLocation()
  const isToday = useMemo(() => DateTime.now().setZone(appliedFilters.timeZone).toFormat("EEE, LLL d") === appliedFilters.date.toFormat("EEE, LLL d"), [appliedFilters.date, appliedFilters.timeZone])
  const relativeDateString = useMemo(() => isToday ? "today" : appliedFilters.date.toRelative({ base: DateTime.now().setZone(appliedFilters.timeZone).startOf('day'), unit: 'days' }), [isToday, appliedFilters.date, appliedFilters.timeZone])

  // const [tick, setTick] = useState(DateTime.now())

  // useEffect(() => {
  //   const tickInterval = setInterval(() => setTick(DateTime.now()), 5000)
  //   return () => clearInterval(tickInterval)
  // })

  return <>
    <Box display="flex" alignItems="center" mb={2}>
      <Box>
        <Chip
          label={schoolLocation?.name}
          size="medium"
          sx={(theme) => ({
            mr: 1,
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
          })}
        />

        <Chip
          label={`${appliedFilters.date.toFormat("EEE, LLL d")} (${relativeDateString})`}
          size="medium"
          sx={(theme) => {
            return {
              mr: 1,
              backgroundColor: isToday ? theme.palette.success.light : theme.palette.warning.light,
              color: isToday ? theme.palette.success.contrastText : theme.palette.warning.contrastText,
            }
          }}
        />

        <Chip
          label={`${filteredCount} / ${totalCount} students`}
          size="medium"
          sx={(theme) => {
            const hasMatches = filteredCount > 0
            return {
              mr: 1,
              backgroundColor: hasMatches ? theme.palette.info.light : undefined,
              color: hasMatches ? theme.palette.info.contrastText : undefined,
            }
          }}
        />

        { appliedFilters.rosterIds.map((id) => {
          const roster = availableRosters.find((roster) => roster.id === id)
          if (!roster) return null

          return <Chip size="medium" key={roster.id} label={`${roster.groupName} ${roster.name}`} sx={{ mr: 1 }} />
        })}

        { appliedFilters.menuIds.map((id) => {
          const menu = availableMenus.find((menu) => menu.id === id)
          if (!menu) return null

          return <Chip size="medium" key={menu.id} label={menu.name} sx={{ mr: 1 }} />
        })}

        { !isSmall && appliedFilters.studentName && appliedFilters.studentName !== '' && <Chip size="medium" label={`Name: "${appliedFilters.studentName}"`} sx={{ mr: 1 }} /> }
      </Box>

      <Box m={1} flexGrow={1} sx={{ textAlign: "right" }}>
        <Box sx={{ width: '100%' }} display={{ xs: 'block', md: 'block' }}>
          {/* { loading ? <LinearProgress color="primary" /> : <Text key={tick.toISO()} component="span" color="slateGray" fontSize="small">Updated {(loadedAt || DateTime.now()).toRelative()}</Text>} */}
        </Box>
      </Box>

      <Box ml={1} display="flex" flexDirection="row" title={(availableMenus.length === 0 || DateTime.now().setZone(appliedFilters.timeZone).startOf('day') < appliedFilters.date) ? "Report Unavailable" : "Download PDF Report"}>
        { isSmall && <Box mr={2}>
          <TextFieldFilter
            size="small"
            filterName="studentName"
            filterDefinitions={filterDefinitions}
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
            setAndApplyFilters={setAndApplyFilters}
            fullWidth={false}
            liveFilter
            simpleField
            InputProps={{
              endAdornment: <IconButton aria-label="clear" size="small" onClick={() => setAndApplyFilters({ studentName: '' })}><CancelIcon /></IconButton>
            }}
          />
        </Box> }

        <Box flexGrow={1} justifySelf="center" alignSelf="center">
          <Button
            href={`/kiosk/reports/passout.pdf?location_id=${schoolLocation?.id}&date=${appliedFilters.date.toFormat("y-LL-dd")}${(appliedFilters.menuIds.length > 0 ? appliedFilters.menuIds : availableMenus.map((menu) => menu.id)).map((menuId) => `&menu_ids[]=${menuId}`)}`}
            disabled={availableMenus.length === 0 || DateTime.now().setZone(appliedFilters.timeZone).startOf('day') < appliedFilters.date}
            fullWidth={false}
            sx={{ p: 1, minWidth: 0 }}
            variant="outlined"
            color="inherit"
          >
            <DownloadIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  </>
}

export default FiltersHeader
