import { useEffect, useMemo } from 'react'
import { groupBy, escapeRegExp } from 'lodash'
import { DateTime } from 'luxon'
import type { KioskUser, Menu, Roster, Scalars, SchoolLocation } from '@kiosk/graphql/schema/graphql'
import { useFilters, Filter, FilterProps as BaseFilterProps } from 'hooks/useFilters'
import { useKioskUsers } from './useKioskUsers'

export interface SummaryProduct {
  id: string
  name: string
  count: number
}

export interface SummarySection {
  id: string
  title: string
  products: SummaryProduct[]
}

export interface FilterState {
  // display: "table" | "grid"
  timeZone: string
  date: Scalars['ISO8601Date']['input']
  studentName: string
  rosterIds: string[]
  menuIds: string[]
  showAll: boolean
  showSummaries: boolean
}

export type FilterProps = Pick<BaseFilterProps<FilterState, KioskUser>, "filters" | "appliedFilters" | "filterDefinitions" | "setFilters" | "applyFilters" | "setAndApplyFilters" | "clearFilters" | "applicable" | "clearable"> & Pick<ReturnType<typeof useKioskUsers>, "loading" | "loadedAt"> & {
  availableRosters: Roster[]
  availableMenus: Menu[]
}

// const displayFilter: Filter<FilterState, KioskUser, 'display'> = {
//   name: 'display',
// }

const timeZoneFilter: Filter<FilterState, KioskUser, 'timeZone'> = {
  name: 'timeZone',
  label: 'Time Zone',
}

const dateFilter: Filter<FilterState, KioskUser, 'date'> = {
  name: 'date',
  label: 'Date',
}

const studentNameFilter: Filter<FilterState, KioskUser, 'studentName'> = {
  name: 'studentName',
  label: 'Student Name',
  match: (kioskUser, { studentName }) => {
    if (!studentName || studentName === '') return true

    const escapedStudentName = escapeRegExp(studentName)
    return (studentName && studentName !== '') && (
      !!(kioskUser.user.firstName || "").match(new RegExp(`${escapedStudentName}`, 'i')) ||
      !!(kioskUser.user.preferredName || "").match(new RegExp(`${escapedStudentName}`, 'i')) ||
      !!(kioskUser.user.middleName || "").match(new RegExp(`${escapedStudentName}`, 'i')) ||
      !!(kioskUser.user.lastName || "").match(new RegExp(`${escapedStudentName}`, 'i'))
    )
  },
}

const rosterFilter: Filter<FilterState, KioskUser, 'rosterIds'> = {
  name: 'rosterIds',
  label: 'Rosters',
  match: (kioskUser, { rosterIds }) => (rosterIds.length === 0 || rosterIds.indexOf(kioskUser.user.roster?.id) > -1),
}

const menuFilter: Filter<FilterState, KioskUser, 'menuIds'> = {
  name: 'menuIds',
  label: 'Menus',
  process: (kioskUser, { menuIds }) => {
    const processedKioskUser = { ...kioskUser, orderProducts: [] }

    kioskUser.orderProducts.forEach((orderProduct) => {
      let match = true

      if (menuIds.length > 0) {
        if (!menuIds.includes(orderProduct.menuProduct.menuId)) match = false
      }

      if (match) {
        processedKioskUser.orderProducts.push(orderProduct)
      }
    })

    return processedKioskUser
  },
}

const showAllFilter: Filter<FilterState, KioskUser, 'showAll'> = {
  name: 'showAll',
  label: 'Has Orders',
  match: (kioskUser, { showAll, studentName, rosterIds }) => {
    if (!showAll) return kioskUser.orderProducts.length > 0

    if ((!studentName || studentName === '') && rosterIds.length === 0) {
      return kioskUser.orderProducts.length > 0
    }

    return true
  },
}

const showSummariesFilter: Filter<FilterState, KioskUser, 'showSummaries'> = {
  name: 'showSummaries',
  label: 'Show Summaries',
}

export const useFilteredKioskUsers = (schoolLocation: SchoolLocation, initialState?: Partial<FilterState>) => {
  const filters = useFilters<FilterState, KioskUser>(
    [
      // displayFilter,
      timeZoneFilter,
      dateFilter,
      studentNameFilter,
      rosterFilter,
      menuFilter,
      showAllFilter,
      showSummariesFilter,
    ],
    {
      // display: "grid",
      timeZone: schoolLocation?.timeZone,
      date: DateTime.fromISO(initialState?.date || DateTime.now().toISO()).setZone(schoolLocation?.timeZone).startOf('day'),
      studentName: '',
      rosterIds: [],
      menuIds: [],
      showAll: false,
      showSummaries: true,
    },
    [
      // 'display',
      'date',
      'menuIds',
      'showAll',
      'showSummaries',
    ]
  )

  const { filterData, appliedFilters, setFilterOptions, setAndApplyFilters } = filters

  const { data, ...rest } = useKioskUsers({
    locationId: schoolLocation?.id,
    date: appliedFilters.date.toISO(),
    pollInterval: 300000,
  })

  const isPast = useMemo(() => DateTime.now().setZone(appliedFilters.timeZone).startOf('day') > appliedFilters.date.startOf('day'), [appliedFilters.date, appliedFilters.timeZone])
  const isPresent = useMemo(() => DateTime.now().setZone(appliedFilters.timeZone).toFormat("EEE, LLL d") === appliedFilters.date.toFormat("EEE, LLL d"), [appliedFilters.date, appliedFilters.timeZone])
  const isFuture = useMemo(() => DateTime.now().setZone(appliedFilters.timeZone).startOf('day') < appliedFilters.date.startOf('day'), [appliedFilters.date, appliedFilters.timeZone])

  const passoutEnabled = useMemo(() => !isFuture, [isFuture])
  const posEnabled = useMemo(() => {
    return isPresent && data?.kioskUsers.some((kioskUser) => kioskUser.posEnabled && !!kioskUser.posPaymentMethod)
  }, [isPresent, data?.kioskUsers])

  const availableRosters = useMemo(
    () =>
      data?.kioskUsers
        .map((kioskUser) => kioskUser.user.roster)
        .filter((v, i, a) => !!v && a.indexOf(v) === i)
        .sort((a, b) => {
          if (!a || !b) return 0
          if (a.sortOrder === b.sortOrder) {
            return `${a.groupName} ${a.name}`.localeCompare(`${b.groupName} ${b.name}`)
          } else {
            return a.sortOrder - b.sortOrder
          }
        }) || [],
    [data?.kioskUsers],
  )

  const availableMenus = useMemo(
    () =>
      data?.kioskUsers
        .flatMap((kioskUser) =>
          kioskUser.orderProducts.flatMap(
            (orderProduct) => orderProduct.menuProduct.menu,
          ),
        )
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    [data?.kioskUsers],
  )

  const filteredKioskUsers = useMemo(() => filterData(data.kioskUsers), [filterData, data.kioskUsers])

  const productSummaries = useMemo(() => {
    return Object.values(groupBy(
      Object.values(groupBy(
        filteredKioskUsers.flatMap((kioskUser) => kioskUser.orderProducts)
        .sort((a, b) => {
          return (a.menuProduct.sortOrder === b.menuProduct.sortOrder) ? a.product.name.localeCompare(b.product.name) : (a.menuProduct.sortOrder - b.menuProduct.sortOrder)
        }),
        (orderProduct) => orderProduct.menuProduct.product.id
      )),
      (orderProducts) => orderProducts[0].menuProduct.section.id
    ))
    .reduce<SummarySection[]>((sections, sectionOrderProducts) => {
      sections.push({
        id: sectionOrderProducts[0][0]?.menuProduct.section.id,
        title: sectionOrderProducts[0][0].menuProduct.section.name,
        products: sectionOrderProducts.reduce<SummaryProduct[]>((products, orderProducts) => {
          products.push({
            id: orderProducts[0].menuProduct.product.id,
            name: orderProducts[0].menuProduct.product.name,
            count: orderProducts.reduce((qty, orderProduct) => qty += orderProduct.quantity, 0),
          })

          return products
        }, []),
      })

      return sections
    }, [])
  }, [filteredKioskUsers])

  useEffect(() => {
    setFilterOptions('rosterIds', availableRosters.map((roster) => ({ key: `${roster?.groupName} ${roster?.name}`, value: roster?.id })))
    setFilterOptions('menuIds', availableMenus.map((menu) => ({ key: menu.name, value: menu.id })))
    setAndApplyFilters({ showSummaries: productSummaries.length > 0, showAll: posEnabled })
  }, [setFilterOptions, setAndApplyFilters, availableRosters, availableMenus, posEnabled, productSummaries.length])

  const totalCount = useMemo(() => data.kioskUsers.length, [data.kioskUsers.length])
  const filteredCount = useMemo(() => filteredKioskUsers.length, [filteredKioskUsers.length])

  return {
    data: {
      ...data,
      filteredKioskUsers,
      productSummaries,
      totalCount,
      filteredCount,
    },
    filters: {
      ...filters,
      availableRosters,
      availableMenus,
    },
    isPast,
    isPresent,
    isFuture,
    passoutEnabled,
    posEnabled,
  ...rest
  }
}

export default useFilteredKioskUsers
