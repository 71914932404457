import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { Box, Text, Card, CardContent, CardHeader, CardActions, Chip, Stack, Button } from 'components'
import { Error as ErrorIcon } from 'icons'
import { User, OrderProduct } from '@kiosk/graphql/schema/graphql'
import { groupBy } from 'lodash'

export interface UserCardProps {
  user: User
  orderProducts: OrderProduct[]
  // TODO: Maybe rename this to passoutLoading?
  loading: boolean
  passedOut: boolean
  passoutEnabled: boolean
  onClickPassout?: (event?: React.MouseEvent) => void
  posEnabled: boolean
  posCartQuantity: number
  onClickAddItems?: (event?: React.MouseEvent) => void
  onClickReportIssue?: (event?: React.MouseEvent) => void
}

export const UserCard: React.FC<UserCardProps> = ({ user, orderProducts, passedOut, passoutEnabled, loading, posEnabled, posCartQuantity, onClickPassout, onClickAddItems, onClickReportIssue }) => {
  return <Card sx={{ display: 'flex', flexDirection: 'column' }}>
    <CardHeader
      title={user.displayName}
      titleTypographyProps={{ color: (theme) => passedOut ? theme.palette.grey[500] : theme.palette.text.primary }}
      subheader={<Chip size="medium" label={`${user.roster?.groupName} ${user.roster?.name}`} />}
      subheaderTypographyProps={{ color: (theme) => passedOut ? theme.palette.grey[500] : theme.palette.text.primary }}
    />

    <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', textWrap: 'balance', }}>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box flexGrow={1} display="flex">
          { orderProducts.length > 0 && <Stack direction="column" flexWrap="wrap">
            { Object.values(groupBy(orderProducts, (orderProduct) => orderProduct.menuProduct.product.id)).map((groupedProducts) => <Box key={groupedProducts[0].menuProduct.product.id} mb={1}>
              <Box
                key={groupedProducts[0].id}
                display="flex"
                alignItems="flex-start"
                py={0.5}
                sx={(theme) => ({ color: groupedProducts.every((orderProduct) => orderProduct.status === 'fulfilled') ? theme.palette.grey[500] : theme.palette.text.primary })}
              >
                <Box flexShrink={0} textAlign="right" sx={(theme) => ({ minWidth: theme.spacing(5) })}>
                  {groupedProducts.reduce((qty, orderProduct) => qty += orderProduct.quantity, 0)} ×
                </Box>
                <Box ml={1} flexGrow={1} textAlign="left">
                  {groupedProducts[0].menuProduct.product.name}
                </Box>
                <Box>
                  { groupedProducts.some((orderProduct) => orderProduct.supportTickets.some((ticket) => ticket.unresolved)) && <ErrorIcon color="warning" titleAccess="Issue reported" /> }
                </Box>
              </Box>
            </Box>) }
          </Stack> }

          { orderProducts.length === 0 && <Box textAlign="center" width="100%">
            <Text variant="body1" sx={(theme) => ({ color: theme.palette.grey[400] })}>{user.preferredName || user.firstName} doesn&apos;t have any orders</Text>
          </Box> }
        </Box>
      </Box>
    </CardContent>

    <CardActions>
      <Box flexGrow={1} display="flex" alignItems="flex-end">
        <Stack direction="column" flexWrap="wrap" width="100%">

          {/* TODO: Turn these into custom button components? */}
          { posEnabled && <Box mb={1}>
            <Button color="primary" onClick={onClickAddItems}>
              Add items { posCartQuantity > 0 && `(${posCartQuantity})`}
            </Button>
          </Box> }

          { passoutEnabled && <Box mb={1}>
            <Button disabled={orderProducts.length <= 0 || loading} color={(passedOut && orderProducts.length > 0) ? "inherit" : "primary"} onClick={onClickPassout}>
              { loading && <CircularProgress color="inherit" size={28} /> }
              { !loading && (orderProducts.length === 0 || !passedOut) && "Mark as passed out" }
              { !loading && orderProducts.length > 0 && passedOut && "Undo passed out" }
            </Button>
          </Box> }

          <Box>
            <Button disabled={!passoutEnabled} variant="text" onClick={onClickReportIssue}>Report an issue</Button>
          </Box>
        </Stack>
      </Box>
    </CardActions>
  </Card>
}

export default UserCard
